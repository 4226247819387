import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '../../api_base/api.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    public closeTab = new Subject<any>();

    public constructor(private api: ApiService) {
    // constructor
    }

    public login(payload): Observable<any> {
        return this.api.postByHeaders('pixlyLogin/login', payload);
    }

    public signUp(payload): Observable<any> {
        payload.origin = 2; // forces verification to true
        return this.api.post('pixlyLogin/register', payload);
    }

    public verifyOtp(payload): Observable<any> {
        return this.api.post('pixlyLogin/verifyotp', payload);
    }

    public resetPwd(payload): Observable<any> {
        return this.api.post('pixlyLogin/resetpassword', payload);
    }

    public verifyCode(payload): Observable<any> {
        return this.api.post('pixlyProfile/verifycode', payload);
    }

    public forgetpass(payload): Observable<any> {
        return this.api.post('pixlyLogin/forgotpassword', payload);
    }

    public otpVerify(payload): Observable<any> {
        return this.api.post('pixlyLogin/forgototpverify', payload);
    }

    public setPassword(payload): Observable<any> {
        return this.api.post('pixlyLogin/verifyuser', payload);
    }

    public verifyUserInvite(payload): Observable<any> {
        return this.api.post('pixlyProfile/verifyuser', payload);
    }

    public logout(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyLogin/revoke', payload);
    }

    public increaseCount(payload): Observable<any> {
        return this.api.postByHeaders1('pixlypayment/updateusageUsercreation', payload);
    }

    public sendOtpInLogin(payload): Observable<any> {
        return this.api.post('pixlyLogin/sendotp', payload);
    }

    public deleteWorkspace(payload): Observable<any> {
        return this.api.deleteByHeadersBearerLoginWorkspace(`pixlyLogin/v2/workspace/user/${payload.masterUser.userId}/tenant/${payload.masterTenant.tenantId}`);
    }

    closeFPTab(value: any) {
        this.closeTab.next(value);
      }

}
